<template>
  <div>
    <v-tooltip top v-if="disabled">
      <template v-slot:activator="{ on, attrs }">
        <div
            class="card-selection"
            :class="{ selected: selected, disabled: disabled }"
            v-on="on"
            v-bind="attrs"
        >
          <h3>{{ title }}</h3>
          <div class="btn-top-right">
            <i
                :class="selected ? 'mdi-circle-slice-8' : 'mdi-circle-outline'"
                class="icon-button las la-circle"
            />
          </div>
          <div class="box-icon">
            <i :class="icon"/>
          </div>
        </div>
      </template>
      <span>{{ disableMessage }}</span>
    </v-tooltip>
    <div v-else>
      <div class="card-selection" :class="{ selected: selected }">
        <h3>{{ title }}</h3>
        <div class="box-icon">
          <v-icon>{{ icon }}</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardSelection",
  props: {
    title: {
      default: 'Titulo',
    },
    icon: {},
    selected: {
      default: null,
    },
    disabled: {},
    disableMessage: {
      default: 'Habitado após conclusão do cadastro',
    }
  },
};
</script>

<style scoped>
.card-selection {
  margin: 10px 0;
  width: 250px;
  height: 245px;
  background: #ffffff;
  padding: 10px;
  border-radius: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
  0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-selection.selected {
  pointer-events: hover;
  border: 2px solid #fd7405;
  box-shadow: 0 0 0 0;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #fd7405; /* If you want dots under the hoverable text */
}

div.disabled {
  /* for "disabled" effect */
  /* cursor: default; */
  opacity: 0.3;
  background: #ccc;
}

.card-selection:hover > div  {
  cursor: pointer;
  transform: scale(1.1);
}

.card-selection:hover > h3  {
  cursor: pointer;
  transform: scale(1.1);
}

.box-icon {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-selection .box-icon i {
  font-size: 100px;
  color: #fd7405;
}

.card-selection h3 {
  color: #fd7405;
  font-size: 20px;
}

.icon-button {
  font-size: 28px;
  color: #fd7405;
}

.btn-top-right {
  position: absolute;
  z-index: 2;
  margin-top: -180px;
  margin-right: -180px;
}
</style>
